<template>
  <teleport to="body">
    <VerificationFullModal
      :show="isModalOpen"
      :showCloseIcon="modals[currentModalKey]?.options?.isClosable"
      :class="modals[currentModalKey]?.options?.class"
      v-if="currentModalKey === ModalKeys.VerificationFull"
      @close="closeModal"
    />
  </teleport>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useModalStore } from '@/components/ModalSystem/ModalSystemStore'
import { ModalKeys } from '@/components/ModalSystem/ModalKeys'
import VerificationFullModal from '@/components/ModalSystem/Modals/VerificationFullModal.vue'

export default {
  computed: {
    ModalKeys() {
      return ModalKeys
    },
  },
  components: { VerificationFullModal },
  setup() {
    const modalStore = useModalStore()

    const currentModalKey = ref('')

    const modals = {
      [ModalKeys.VerificationFull]: VerificationFullModal,
    }

    const isModalOpen = computed(() => {
      const openModal = Object.values(modalStore.getModals()).find(
        (modal) => modal.isOpen,
      )
      console.log('isModalOpen:', openModal !== undefined)
      return openModal !== undefined
    })

    const closeModal = () => {
      if (
        modals[currentModalKey]?.options &&
        modals[currentModalKey]?.options?.close
      ) {
        modals[currentModalKey]?.options?.close()
      }
      modalStore.closeModal(currentModalKey.value)
    }

    watch(
      () => modalStore.getModals(),
      (newModals) => {
        const openModal = Object.values(newModals).find((modal) => modal.isOpen)
        if (openModal) {
          currentModalKey.value = openModal.key
        } else {
          currentModalKey.value = null
        }
      },
      { deep: true, immediate: true },
    )

    return {
      currentModalKey,
      modals,
      modalStore,
      isModalOpen,
      closeModal,
    }
  },
}
</script>
